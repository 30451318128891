<template>
    <div class="menu-item-sections-grid" v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="containers mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/POS/menu-categories' }">Menu Categories</el-breadcrumb-item>
                  <el-breadcrumb-item>{{ $route.params.menuCategoryName }} - Items</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Items in "{{ $route.params.menuCategoryName }}"</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div>
                <el-button
                  type="primary"
                  :disabled="false"
                  @click="$router.push( { path: `/POS/menu-category/${$route.params.CategoryID}/${$route.params.menuCategoryName}/add-menu-item` } )"
                  round
                >Add Menu Item</el-button>
              </div>
            </div>


            <section>
              <article>
                <div class="menu-items-grid">
                  <div 
                    v-for="item in menuItems" 
                    :key="item.MenuItemID"
                  >
                    <img
                      :src="item.ItemImage"
                    >
                    <div class="item-desc">
                      <div>
                        <div class="text-center">
                          <span>{{ item.ItemName }}</span>
                        </div>
                        <div>
                          <el-row style="display: flex; align-items:center; justify-content:center;">
                          <el-tooltip
                            content="Edit"
                            placement="top"
                          >
                            <el-button
                              type="success"
                              :disabled="false"
                              @click="addItemToOrderList(item)"
                              icon="el-icon-plus"
                              circle
                            ></el-button>
                          </el-tooltip>
                          <el-tooltip
                            content="Edit"
                            placement="top"
                          >
                            <el-button
                              type="warning"
                              :disabled="false"
                              @click="showEditProductData(item.MenuItemID)"
                              icon="el-icon-edit"
                              circle
                            ></el-button>
                          </el-tooltip>
                          <el-tooltip
                            content="Delete"
                            placement="top"
                          >
                            <el-button
                              type="danger"
                              :disabled="false"
                              @click="deleteMenuItem(item.MenuItemID)"
                              icon="el-icon-delete"
                              circle
                            ></el-button>
                          </el-tooltip>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </section>

            <!-- <hr />
  
            <div
              v-loading="loading || is_changing"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="menuItems"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getMenuItems"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Menu Items Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Menu Items</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Main Image"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="75px"
                >
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.ItemImage"
                      style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                    >
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Item Name"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.ItemName }} </span><br />
                    <small>Add On: {{ formattedDate(scope.row.AddedDate) }} </small>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Status"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="100px"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex;">
                      <el-tooltip
                        :content="scope.row.isActive ? 'Active' : 'Deactivated'"
                        placement="top"
                      >
                        <el-switch
                          v-model="scope.row.isActive"
                          @change="switchStatus(scope.row.isActive, scope.row.MenuItemID)"
                          active-color="#13ce66"
                          inactive-color=""
                        >
                        </el-switch>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          type="success"
                          :disabled="false"
                          @click="addItemToOrderList(scope.row)"
                          icon="el-icon-plus"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          type="warning"
                          :disabled="false"
                          @click="showEditProductData(scope.row.MenuItemID)"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          :disabled="false"
                          @click="deleteMenuItem(scope.row.MenuItemID)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div> -->
  
          </div>
        </article>
      </section>
      <section>
        <article class="order-list-section">
          <div>
                <h2>Order List</h2>
                <ul class="responsive-table">
                  <li class="table-header">
                    <div class="col col-1">Item</div>
                    <div class="col col-2">Qty</div>
                    <div class="col col-3">Price</div>
                  </li>
                  <li class="table-row" v-for="item in orderLists" :key="item.MenuItemID">
                    <div class="col col-1" data-label="Job Id">
                      <img :src="item.ItemImage" style="width: 40px; height: 40px; border-radius: 50%; object-fit: cover;">
                    </div>
                    <div class="col col-2" data-label="Customer Name">{{ item.Quantity }}</div>
                    <div class="col col-3" data-label="Amount">{{ item.SubTotal }}</div>
                  </li>
                </ul>
          </div>
          <div class="payment-article">
            <h1>Hello Payment.</h1>
          </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  // import ScaleOut from "@/components/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      // ScaleOut
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showUserEditDialog: false,
        menuItems: [],
        user_data: {},
        tableProps: {
          border: true,
          stripe: true,
        },
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
        orderLists: []
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getMenuItems();
      this.loadOrderList();
    },
  
    methods: {
      showEditProductData(MenuItemID) {
        this.$router.push({
          path: `/POS/menu-category/${this.$route.params.CategoryID}/${this.$route.params.menuCategoryName}/edit-menu-item/${MenuItemID}`,
        });
      },
  
      closeEditUserData() {
        this.showUserEditDialog = false;
      },
  
      async getMenuItems() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `menu-categories/get-one/${this.$store.state.company.company_id}/${this.$route.params.CategoryID}`
          );
          if (request.data.success) {
            this.menuItems = request.data.menuItems;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch menuItems now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async switchStatus(activation_status, MenuItemID) {
        try {
          this.is_changing = true;
          let request = await this.$http.patch(
            `menu-items/change_is_active_status/${MenuItemID}`, {
              isActive: activation_status,
            }
          );
          if (
            request.data.success &&
            request.data.message == "ITEM_STATUS_CHANGED_SUCCESSFULLY"
          ) {
            this.getMenuItems();
            // this.showSuccessMessage("Success", );
                return this.$notify({
                  title: "Success",
                  message: "Menu Item status changed",
                  type: "success",
                });
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.is_changing = false;
            // return this.showFailedMessage(
            //   "Connection failed",
            //   "A network error occurred, please try again."
            // );
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
          }
          this.loadingError = true;
        //   this.showFailedMessage(
        //     "Unable to change staff status",
        //     "An unexpected error occurred, please try again"
        //   );
        return this.$notify({
                  title: "Unable to change Menu Item status",
                  message: "An unexpected error occurred, please try again",
                  type: "error",
                });
        } finally {
          this.is_changing = false;
        }
      },
  
      async deleteMenuItem(MenuItemID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Menu Item. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`menu-items/${MenuItemID}`);
              if (
                request.data.success &&
                request.data.message === "ITEM_DELETED_SUCCESSFULLY"
              ) {
                this.getMenuItems();
                this.$notify({
                  title: "Success",
                  message: "Menu Item deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete Menu Item, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },

      
      addItemToOrderList(MenuItem) {
        // Retrieve the existing order list from local storage or initialize an empty array
        let orderList = JSON.parse(localStorage.getItem('orderList')) || [];

        // Find the existing item in the order list
        let existingItem = orderList.find(item => item.MenuItemID === MenuItem.MenuItemID);

        if (existingItem) {
          // If the item exists, increase the quantity and update the subtotal
          existingItem.Quantity += 1;
          existingItem.SubTotal += MenuItem.Price;
        } else {
          // If the item does not exist, add the new item to the order list
          orderList.push({
            ItemImage: MenuItem.ItemImage,
            MenuItemID: MenuItem.MenuItemID,
            Quantity: 1,
            SubTotal: MenuItem.Price,
            AddedBy: this.$store.state.userId,
            CompanyID: this.$store.state.company.company_id,
            BranchID: 1
          });
        }

        // Store the updated order list back to local storage
        localStorage.setItem('orderList', JSON.stringify(orderList));
        this.loadOrderList();

        // Optionally, display a message or update the UI to reflect the change
        // alert(`Item with ID: ${MenuItem.MenuItemID} has been added to the Order Menu`);
      },

        
      loadOrderList() {
        this.orderLists = JSON.parse(localStorage.getItem('orderList')) || [];
      }

    },
  };
  </script>
  

  <style scoped>
  .menu-item-sections-grid {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .menu-item-sections-grid > section{
    padding: 10px;
  }
  .menu-items-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  .menu-items-grid > div{
    /* border: 1px solid black; */
    background-color: #F8F9FA;
    height: 200px;
    cursor: pointer;
    position: relative;
  }
  .menu-items-grid > div img{
    width: 100%; 
    object-fit: cover;
    height: 200px;
    cursor: pointer;
    position: relative;
  }

  .menu-items-grid .item-desc{
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.order-list-section {
  position: relative;
}
.order-list-section .payment-article{
  position: fixed;
  background-color: #000;
  bottom: 0px;
  width: 100%;
}
  

h2 {
  font-size: 1.2em;
  font-weight: 600;
  margin: 20px 0;
}
.responsive-table {
  padding: 0px !important;
}

.responsive-table li {
  border-radius: 3px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px !important;
  margin-bottom: 10px;
}
.responsive-table .table-header {
  background-color: #95A5A6;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.responsive-table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.responsive-table .col-1 {
  flex-basis: 10%;
}
.responsive-table .col-2 {
  flex-basis: 40%;
}
.responsive-table .col-3 {
  flex-basis: 25%;
}
.responsive-table .col-4 {
  flex-basis: 25%;
}
@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }
  .responsive-table li {
    display: block;
  }
  .responsive-table .col {
    flex-basis: 100%;
  }
  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }
  .responsive-table .col:before {
    color: #6C7A89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}
  </style>

  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>